(function(wnd){
    function ProcedureForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    ProcedureForm.prototype = {
        form: undefined,
        notyf: undefined,
        button: undefined,
        sendedForm: false,
        dateField: false,
        texts: false,
        radios: false,
        hidable: false,
        infos: {},
        modifiable: true,
        ownerChk: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.notyf = new Notyf({
                delay:5000,
                alertIcon: 'notyf-error-icon',
                confirmIcon: 'notyf-confirm-icon'
            });
            this.button = this.form.find('[data-purpose="save-button"]');
            this.dateField = this.form.find('.date-field');
            this.radios = this.form.find('[name="grave_existing"]');
            this.hidable = this.form.find('.hidable-row');
            this.ownerChk = this.form.find('#equal-to-customer');            

            if (this.modifiable === 'false') {
                $.each(this.form.serializeArray(), function(index, value){
                    
                    field = $('[name="' + value.name + '"]');
                    
                    field.attr('readonly', 'readonly');

                    field.addClass('disabled');

                    if (field.attr('type') == 'radio') {
                        field.attr('disabled', true);
                    }
                });

                this.form.find('select').each(function(){
                    $(this).prop('disabled', 'disabled');
                });

                this.dateField.datepicker( "option", "disabled", true );                
            }

            this.showDependContent();
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.button.length > 0) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }

            this.form.find('.select').on('change', function(){
                
                var name = $(this).attr('name');
                var infoBox = _self.form.find('.info-box[data-name="'+name+'"]');
                if (infoBox.length > 0) {
                    var text = _self.getInfo(name, $(this).val());
                    
                    infoBox.html(text);
                    if (text.trim() != '' && !infoBox.is(':visible')) {
                        infoBox.show();
                    } else if (text.trim() === '' && infoBox.is(':visible')) {
                        infoBox.hide();
                    }
                }
            }).change();            

            this.radios.on('change', function(){

                var name = $(this).attr('name');
                var infoBox = _self.form.find('.info-box[data-name="'+name+'"]');
                var text = _self.getInfo(name, $(this).val());

                if($(this).prop('checked') == true && $(this).val() == 'exist' && !_self.hidable.is(':visible')) {
                    
                    infoBox.html(text);
                    if (text.trim() != '') {   
                        infoBox.show();
                    } else {
                        infoBox.hide();
                    }
                    _self.hidable.show();                                        
                } else if ($(this).prop('checked') == true && $(this).val() == 'new') {
                    
                    infoBox.html(text);
                    if (text.trim() != '') {      
                        infoBox.show();
                    } else {
                        infoBox.hide();
                    }
                    _self.hidable.hide();    

                } else {
                    _self.hidable.hide();       
                    infoBox.hide();                                 
                }

            }).change();

            this.form.find('.depends-change').on('change', function(){                
                _self.showDependContent();
            });

            this.ownerChk.on('change', function(){
                if ($(this).prop('checked') == true) {
                    _self.form.find('.owner-content').hide();
                    _self.form.find('.owner-content').find('input').attr('disabled', true);
                    _self.form.find('.owner-content').find('select').prop('disabled', true);  
                } else {
                    _self.form.find('.owner-content').show();
                    _self.form.find('.owner-content').find('input').attr('disabled', false);
                    _self.form.find('.owner-content').find('select').prop('disabled', false);
                }
            }).change();

            $('#tombstone-demolition-value').on('focus', function() {
                $('#tombstone-demolition-aksd').prop('checked', false);
                $('#tombstone-demolition-other').prop('checked', true);                
            });
        },
        showDependContent: function() {                                

            $('[data-purpose="depends-on-row"]').each(function(index,item){
                var _self = this;
                var visible = true;
                var keys = $(item).data('key');            

                var dependFields = keys.split('|');                
                var count = dependFields.length;
                
                for (var i = 0; i<count; i++) {
                    var values = dependFields[i].split('-');
                    var fieldName = values.shift();
                    
                    var field = $('[name = '+fieldName+']');                    
                    var value = field.val();
                    
                    if (field.is(':radio')) {
                        value = '';
                        $.each(field, function(){
                            if ($(this).prop('checked') == true) {
                                value = $(this).val();
                            }
                        });
                    }
                    
                    if (values.indexOf(value) < 0) {
                        visible = false;
                    } 
                }

                if (visible) {
                    $(_self).show();
                    $(_self).find('input').attr('disabled', false);
                    $(_self).find('select').prop('disabled', false);                    
                } else {
                    $(_self).hide();
                    $(_self).find('input').attr('disabled', true);
                    $(_self).find('select').prop('disabled', true);                    
                }
                
            });
        },
        setParams: function(params) {
            if (params.infos) {this.infos = $.extend({}, this.infos, params.infos);}
            if (params.modifiable) {this.modifiable = params.modifiable;}
        },
        sendForm: function() {
            var _self = this;

            if (!this.sendedForm) {
                this.sendedForm = true;
                
                $.ajax({
                    url:this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data: _self.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            if ((response.msg).trim() !== '') {
                                _self.notyf.confirm(response.msg);
                            }
                            setTimeout(function(){
                                document.location.href=response.url
                            }, 2000);
                        } else {
                            if (typeof response.errors == 'object') {
                                $.each(response.errors, function(key, value) {
                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }

                                    var input = _self.form.find('[name="'+key+'"]');
                                    if (input.length > 0) {
                                        input.parent().addClass('has-error');
                                        input.addClass('has-error');
                                    }
                                });
                            }

                            setTimeout(function() {
                                _self.sendedForm = false;
                            }, 500);
                        }
                    },
                });
            }
        },
        getInfo: function(name, key) {            
            return typeof this.infos[name] != 'undefined' && typeof this.infos[name][key] != "undefined" ? this.infos[name][key] : "";
        },
    };

    wnd.ProcedureForm = ProcedureForm;
})(window);