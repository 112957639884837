(function(wnd){
    function ProcedureList(container, params) {
        try {
            if (!container) {
                throw 'Form is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    ProcedureList.prototype = {
        administrators: {},
        texts: {},
        popupButton:false,
        container:false,
        popupContainer: false,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.popupButton = this.container.find('[data-purpose="admin-popup"]');
            this.popupContainer = this.createContainer();
            $('body').append(this.popupContainer);
        },
        addDOMEvents: function() {
            var _self = this;

            this.popupButton.on('click', function(){
                _self.createAdminPopup(_self.administrators[$(this).data('admin-id')]);
            });
        },
        setParams: function(params) {
            if(params.administrators){
                this.administrators = $.extend({}, this.administrators, params.administrators);
            }
            if (params.texts) {
                this.texts = $.extend({}, this.texts, params.texts);
            }
        },
        createContainer: function(){
            return $('<div>', {'class': 'admin-popups-outer'});
        },
        createAdminPopup: function(adminData) {
            var _self = this;

            var content = $('<span>', {
                'class':'admin-popup-item '+adminData.admin_id
            });
            content.append('<span class="close-btn"></span>');
            content.append('<span class="popup-title">'+_self.texts.popup_title+'</span>');
            if(adminData.img) {
                content.append('<span class="image" style="background:url(../assets/img/admin_images/'+adminData.img+') no-repeat center center/ 100% auto;"></span>');
            }
            if(adminData.name) {
                content.append('<span class="name">'+adminData.name+'</span>');
            }
            if(adminData.title) {
                content.append('<span class="title">'+adminData.title+'</span>');
            }

            if(adminData.phone) {
                var link = adminData.phone.replace('(','');
                var link = link.replace(')','');
                var link = link.replace('-','');
                var link = link.replace(/\s/g,'');
                content.append('<span class="phone"><span class="mobil">mobil:</span><a href="tel:'+link+'">'+adminData.phone+'</a></span>');
            }

            this.popupContainer.append(content);

            content.find('.close-btn').click(function(){	
				var tmp = $(this);				
                tmp.parent().fadeOut(300);
                tmp.parent().remove();				
			});
        }		
    };

    wnd.ProcedureList = ProcedureList;
})(window);